@import '../../../assets/styles/vars'

.pnd-screen
  display: flex
  flex-direction: column
  width: 100%
  background: url(../../../assets/images/screen-background.png)
  background-size: cover
  height: 100vh
  .pnd-header
    padding: 40px

    .pnd-header__back-button-wrapper
      position: absolute
      top: 0
      left: 0
      .back-button
        padding: 60px 60px 60px 40px

    .pnd-header__info
      display: flex
      padding-left: 60px
      svg
        width: 140px
        height: auto
        margin: 0 40px 0 60px

      .pnd-header__info-title
        flex-shrink: 0
        font-size: 52px
        font-weight: 600
        margin-top: -10px

      .pnd-header__info-address
        margin-top: 10px
        flex-shrink: 0
        font-size: 32px
        font-weight: $book
        color: $gray2

    .pnd-header__search
      display: flex
      margin-top: 40px
      width: 100%

      .pnd-header__search-input-wrapper
        width: 100%
        background: $gray3
        display: flex
        padding: 10px 20px 10px 20px
        align-items: center
        justify-content: space-between
        border-radius: 8px

      .pnd-header__search-input-wrapper svg path
        fill: $gray4

      .pnd-header__search-input
        width: 80%
        height: 70px
        background: transparent
        border: none
        font-size: 40px
        font-weight: 600
        color: rgba(black, 0.8)
        @include placeholder()
          font-size: 34px
          font-weight: 400

  .pnd-list__header
    background: #06335D

  .pnd-list__header-column
    padding: 20px 0
    font-size: 30px
    font-weight: 600
    text-align: left
    &.pnd-list__header-column-name
      padding-left: 20px
    &.pnd-list__header-column-unit
      width: 150px
    &.pnd-list__header-column-action
      padding-right: 20px

  .pnd-list
    display: flex
    flex: 1 1
    flex-direction: column
    justify-content: flex-start
    align-items: center
    overflow-x: hidden
    overflow-y: auto

    .spinner-wrapper
      display: flex
      width: 100%
      height: 60%
      justify-content: center
      align-items: center

    .pnd-list__placeholder
      display: flex
      width: 100%
      height: 60%
      flex-direction: column
      justify-content: center
      align-items: center

      .pnd-list__placeholder-text
        font-size: 50px
        font-weight: 500
        margin-top: 60px

    .pnd-list__table-wrapper
      display: flex
      flex-direction: column

    .pnd-list__table
      width: 100%
      border-spacing: 0
      //table-layout: fixed
      .button__filled-green
        margin-left: auto
        display: block

      .user-pic
        flex-shrink: 0

      .pnd-list__table-row
        height: 160px
        align-items: center
        td:first-child
          padding-left: 40px
        td:last-child
          padding-right: 20px

      .pnd-list__table-row__item
        flex-direction: row
        flex: 1
        align-items: center

      .pnd-list__table-row__item-user
        display: flex
        align-items: center
        min-width: 0
      .pnd-list__table-row__item-initials
        font-size: 48px
        font-weight: $demi
        text-align: left
        margin-left: 20px
        margin-right: 20px
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden

      .pnd-list__table-row__item-unit
        font-size: 42px
        font-weight: 400
      .pnd-list__table-row__connect-button
        width: 230px
        min-width: auto
        display: flex
        flex-direction: row
        align-items: center

  .pnd-footer
    display: flex
    justify-content: center
    align-items: center
    height: 180px
    background: #03182D

@import '../../../assets/styles/vars.sass'

.context-form-theme-drafty
  max-width: 640px
  .form-field
    margin-top: 15px
    &.required
      .form-field__label:before
        position: absolute
        right: 100%
        transform: translateX(-2px)
        content: "*"
        color: $red
    &.has-error
      input
        border-color: $red
  .form-field__label-holder
    padding-bottom: 4px
    label
      position: relative
      font-size: 14px
      color: $dimmed
  .form-field__input-container
    display: flex
    flex-grow: 1
    flex-direction: column
    &>div
      width: 100%
    input, textarea
      color: $darkgray
      font-size: 14px
      border: 1px solid darken($gray3, 10)
      background: $gray3
      padding: 10px 10px
      border-radius: 4px
      display: flex
      flex-grow: 1
      transition: border 150ms
      &:focus
        background: lighten($gray3, 2)
        border: 1px solid darken($gray3, 20)
  .form-field__input-container2
    input
      color: $darkgray
      border: none
      font-size: 16px
      border-bottom: 1px solid darken($gray3, 5)
      padding: 5px 0 5px
      width: 100%
      transition: border-color 250ms
      &:focus
        border-color: lighten($blue, 20)
  .form-field__description
    display: block
    color: $dimmed
    font-size: 14px
    padding-top: 2px
  .form-field__errors-holder
    margin-top: 3px
    .form-field__errors-message
      font-size: 14px
      color: $red

  .form-footer__holder
    margin-top: 40px
    border-top: 1px solid $gray3
    padding-top: 30px
    button
      margin-left: 10px
      &:first-child
        margin-left: 0
  h4
    color: $dimmed
h4
  font-weight: 400
  font-size: 18px
  margin-top: 20px
  margin-bottom: 5px
  padding-bottom: 7px
  color: $gray1
  border-bottom: 1px solid $gray3

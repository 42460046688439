@import '../../../assets/styles/vars'

.vendors-screen
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%
  height: 100%
  padding-top: 200px
  box-sizing: border-box

  .vendors-screen__back-button__wrapper
    position: fixed
    left: 0
    z-index: 2

    .back-button
      padding: 60px 60px 60px 40px

  .vendors-screen__header
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    height: 200px
    flex-shrink: 0
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 1
    //background: linear-gradient(#06182E 0%, #063C68 60%, #063C68 70%, #06182E 100%)

    .vendors-screen__title
      font-size: 48px
      font-weight: 600
      color: #F9C038

    .vendors-screen__subtitle
      display: flex
      align-items: center
      font-size: 36px
      font-weight: 500
      color: white
      margin-top: 15px

      .icon
        width: 34px
        height: 34px
        margin-right: 20px

  .vendors-screen__content-wrapper
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    flex: 1
    overflow-y: auto
    background: #F2F2F2

    .vendors-screen__content
      display: flex
      flex-direction: column
      justify-content: flex-start
      width: 86%
      height: 100%
      background: white

      .spinner-wrapper
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        height: 100%

ul,
ol
  margin: 0
  padding: 0
  list-style: none

h1,h2,h3,h4,h5,
.h1,.h2,.h3,.h4,.h5
  margin: 0

body
  margin: 0

p,
form
  margin: 0

fieldset
  border: 0
  margin: 0
  padding: 0
  min-width: auto

label[for]
  cursor: pointer

legend
  border: 0
  padding: 0
  white-space: normal

button,
input,
select,
textarea
  font-size: 100%
  margin: 0
  vertical-align: baseline
  outline: none !important

button,
input
  line-height: normal

button
  background: none
  border: 0
  padding: 0

button,
input[type="button"],
input[type="reset"],
input[type="submit"]
  cursor: pointer
  -webkit-appearance: button

button[disabled],
input[disabled]
  cursor: default

input[type="checkbox"],
input[type="radio"]
  box-sizing: border-box
  padding: 0

input[type="search"]
  -webkit-appearance: textfield
  -moz-box-sizing: content-box
  -webkit-box-sizing: content-box
  box-sizing: content-box


button::-moz-focus-inner,
input::-moz-focus-inner
  border: 0
  padding: 0
textarea
  overflow: auto
  vertical-align: top
  resize: vertical

html, body
  -webkit-font-smoothing: antialiased
  @media only screen and (max-device-width: 480px)
    -webkit-text-size-adjust: none

input:invalid,
textarea:invalid
  background: none

@import '../../../../../assets/styles/vars'

.caller-view
  position: relative
  width: 100%
  height: 100%
  background: url(../../../../../assets/images/screen-background.png)
  background-size: cover

  .content
    position: absolute
    width: 100%
    height: 100%


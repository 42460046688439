@import 'defaults'
@import 'vars'
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800)
@import 'dearJoe-4'

html
  min-height: 100%
  display: flex
  background: $lightgray
  max-width: 100%

#root
  flex-grow: 1
  flex-shrink: 0
  max-width: 100%
  display: flex
  background: #032541
  //background: radial-gradient(circle at 10%, #01477a 0%, #032541 15%)
  background: linear-gradient(#032541 0%, #034e84 20%, #01477a 50%, #034e84 80%, #032541 100%)

body, button, input, textarea
  font-family: 'Montserrat', sans-serif

*
  box-sizing: border-box

body
  display: flex
  max-width: 100%
  flex-grow: 1
  flex-shrink: 0
  font-size: 30px
  color: #ffffff

a
  color: $blue
  text-decoration: none
  &:focus
    outline: none

.well
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 40px
  &.row
    flex-direction: row

.max-width
  max-width: 1280px
  margin: auto

.ReactModalPortal > *
  opacity: 0
.ReactModal__Overlay
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  background: rgba(255, 255, 255, 0.8)
  display: flex
  justify-content: center
  align-items: center
  transition: opacity 200ms ease-in-out
  &--after-open
    opacity: 1
    .drafty-modal
      transform: scale(1) !important
  &--before-close
    opacity: 0

.primary
  color: $primary

.pill
  font-size: 12px
  font-weight: 500
  height: 16px
  padding: 2px 10px
  align-items: center
  justify-content: center
  border-radius: 20px
  background: lighten($dimmed, 20)
  color: $black1
  display: inline-flex
  margin-left: 5px
  &.primary
    background: lighten($primary, 30)
    color: #2f5e7e
  &.success
    background: lighten($green, 30)
    color: #307e30
  &.error
    background: lighten(#ea6868, 20)
    color: #b42d19

@import '../../../assets/styles/vars'

.page-wrapper
  height: 100%
  max-width: 100%
  display: flex
  flex-grow: 1
  flex-direction: column
  background: url(../../../assets/images/screen-background.png)
  background-size: cover

@import '../../../../../assets/styles/vars'

.invite-button
  width: 210px
  height: 100px
  background-repeat: no-repeat
  transition: all 250ms
  &:disabled
    pointer-events: none
  &:active svg rect
    fill: $black1
    opacity: 0.5

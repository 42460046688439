@import '../../../assets/styles/vars'

.settings-screen
  display: flex
  flex: 1
  flex-direction: column
  justify-content: center

  .settings-screen__header-title
    position: absolute
    top: 0
    align-self: center
    margin: 50px 0 50px 0
    font-weight: 600
    font-size: 48px

  .settings-screen__loader-holder
    display: flex
    flex: 1
    flex-direction: column
    padding-top: 30%

    .settings-screen__loader-text
      margin-top: 40px
      font-size: 44px
      font-weight: 400

  .settings-screen__content
    display: flex
    flex: 1
    flex-direction: column
    width: 65%
    padding-top: 5%
    justify-content: center
    align-self: center

    .settings-screen__devices-block
      height: 25%
      flex-direction: column
      margin: 50px 0 50px 0

      .settings-screen__devices-block-title
        margin-bottom: 40px
        font-size: 32px
        font-weight: 500
        text-align: center

      .settings-screen__devices-list
        height: 90%
        flex-direction: column
        overflow-y: scroll
        border: 1px solid $placeholder
        border-radius: 5px
      .settings-screen__devices-list::-webkit-scrollbar
        width: 20px
        background-color: transparent
      .settings-screen__devices-list::-webkit-scrollbar-track
        border-radius: 10px
        background-color: transparent
      .settings-screen__devices-list::-webkit-scrollbar-thumb
        border-radius: 10px
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
        background-color: $gray

      .settings-screen__device-holder
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding: 20px 10px 20px 35px

        .settings-screen__device-label
          font-size: 30px
          font-weight: 400
          max-width: 75%
        .settings-screen__device-label.selected
          font-weight: 500
          color: #77bb41
    .settings-screen__continue-button
      width: 80%
      font-size: 28px
      font-weight: 600
      margin-top: 8%
      align-self: center

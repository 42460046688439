@import '../../../assets/styles/vars'

.simple-keyboard.hg-theme-ios
  background-color: #dee3ed
  padding: 5px
  position: fixed
  bottom: 0
  width: 100%
  z-index: 9999

.simple-keyboard.hg-theme-ios.presented
  transition: all 320ms ease-out
  opacity: 1
  transform: translateY(0)

.simple-keyboard.hg-theme-ios.collapsed
  transition: all 160ms ease-in
  opacity: 0
  pointer-events: none
  transform: translateY(500px)

.simple-keyboard.hg-theme-ios .hg-row .hg-button
  flex-grow: 1
  cursor: pointer
  max-width: initial

.simple-keyboard.hg-theme-ios .hg-row
  display: flex

.simple-keyboard.hg-theme-ios .hg-row:not(:last-child)
  margin-bottom: 5px

.simple-keyboard.hg-theme-ios .hg-row .hg-button:not(:last-child)
  margin-right: 5px

.simple-keyboard.hg-theme-ios .hg-row:nth-child(2)
  margin-left: 18px

.simple-keyboard.hg-theme-ios.hg-layout-custom
  background-color: #e5e5e5
  padding: 5px

.simple-keyboard.hg-theme-ios .hg-button
  border-radius: 5px
  box-sizing: border-box
  padding: 0
  background: white
  border-bottom: 1px solid #b5b5b5
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  box-shadow: none
  font-weight: 500
  font-size: 48px
  max-width: 70px
  min-width: 60px
  height: 7vh
  min-height: 7vh
  color: black

.simple-keyboard.hg-theme-ios .hg-button:active
  background-color: #CBD3D9

.simple-keyboard.hg-theme-ios .hg-button.hg-functionBtn
  background-color: #adb5bb
  color: white

.simple-keyboard.hg-theme-ios .hg-button.hg-functionBtn:active
  background-color: #CBD3D9

.simple-keyboard.hg-theme-ios .hg-button.hg-button-space
  background-color: #ffffff

.simple-keyboard.hg-theme-ios .hg-button-space
  max-width: 448px
  min-width: 448px

.simple-keyboard.hg-theme-ios .hg-button-enter
  max-width: 110px
  min-width: 110px

.simple-keyboard.hg-theme-ios .hg-button-back
  min-width: 80px
  max-width: 80px








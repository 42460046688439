@import '../../../../../assets/styles/vars'

.code-denied
  display: flex
  align-items: center
  flex-direction: column
  width: 100%
  height: 100%
  .cloud
    width: 600px
    height: 400px

.code-denied__message
  margin-top: 60px
  font-size: 46px
  text-align: center
  line-height: 50px
  white-space: pre

.code-denied__message-bold
  font-weight: 700

.code-denied__cancel-button
  align-self: center
  margin-top: 180px
  font-weight: 600
  width: 480px
  border-radius: 100px
  font-size: 34px
  box-shadow: 0 5px 10px 0 rgba(#000000, .2)

@import '../../../../../assets/styles/vars'

.granted-view__wrapper
  display: flex
  flex-direction: column
  justify-content: center
  width: 100%
  height: 100%

  .granted-view
    display: flex
    align-items: center
    flex-direction: column
    justify-content: space-around

    .granted-view__text-title
      margin-top: 100px
      font-size: 180px
      text-align: center
      font-family: $dearJoe4Font

    .granted-view__text-subtitle
      margin-top: -90px
      font-size: 48px
      text-align: center

      .granted-view__text-door
        font-weight: 600

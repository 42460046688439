@import '../../../../../../assets/styles/vars'

.content-photo
  display: flex
  position: relative
  width: 100%
  height: 100%
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  .webcam
    width: 100%
    height: 100%
    position: absolute
    object-fit: cover
    .webcam-video
      width: 100%
      height: 100%
      object-fit: cover
      overflow: hidden

  .content-photo__border
    position: absolute
    display: flex
    width: 90%
    height: 80%
    justify-content: space-between
    align-content: space-between
    flex-wrap: wrap

    & > div
      width: 60px
      height: 60px
      border: 4px solid rgba($white, 0.7)

      &:nth-child(n+3)
        order: 2
        align-self: flex-end

    &::before
      content: ''
      order: 1
      flex-basis: 100%

    .top-left
      border-right: 0
      border-bottom: 0

    .top-right
      border-left: 0
      border-bottom: 0

    .bottom-right
      border-right: 0
      border-top: 0

    .bottom-left
      border-left: 0
      border-top: 0

  .content-photo__container
    display: flex
    position: absolute
    flex-direction: column
    justify-content: space-between
    align-items: center

    .content-photo__title
      text-align: center
      font-size: 56px
      font-weight: 600

    .content-photo__subtitle
      text-align: center
      font-size: 38px
      margin-top: 20px

    .content-photo__counter
      display: flex
      justify-content: center
      align-items: center
      width: 400px
      height: 400px
      margin-top: 120px
      border-radius: 50%
      background: rgba($green, 0.4)
      .content-photo__counter-text
        flex: 1
        text-align: center
        font-size: 140px
        font-weight: 700
        padding: 10px
        margin: 0 auto

  .content-photo__snapshot-wrapper
    transform: rotateY(180deg)
    position: absolute
    width: 100%
    height: 100%
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    & > img
      width: 100%
      height: 100%
      object-fit: cover


@import '../../../../../assets/styles/vars'

.emergency-granted__wrapper
  display: flex
  flex-direction: column
  justify-content: center
  width: 100%
  height: 100%
  background: linear-gradient(#06182E 0%, #063C68 60%, #063C68 70%, #06182E 100%)

.emergency-granted
  display: flex
  align-items: center
  flex-direction: column
  justify-content: space-around

.emergency-granted__text-title
  margin-top: 100px
  font-size: 180px
  text-align: center
  font-family: $dearJoe4Font

.emergency-granted__text-subtitle
  margin-top: -70px
  font-size: 46px
  text-align: center

.emergency-granted__text-door
  font-weight: 600

.emergency-granted__cancel-button
  position: absolute
  width: 100%
  height: 100%

@import '../../../assets/styles/vars'

.maintenance
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%

  .maintenance-content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  .maintenance__text
    padding: 100px
    font-size: 42px
    font-weight: 500
    text-align: center
    line-height: 65px

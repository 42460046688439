@import '../../../assets/styles/vars'

.button
  min-width: 300px
  padding: 0 40px
  display: inline-flex
  flex-grow: 0
  align-items: center
  justify-content: center
  font-size: 32px
  font-weight: 500
  transition: all 200ms
  color: $white
  &:disabled
    opacity: 0.8
    pointer-events: none
.button__filled-green
  background: $primary
  &:active
    background: lighten($primary, 10)
.button__filled-white
  background: $white
  &:active
    background: darken($white, 5)
.button__filled-red
  background: #FF4242
  &:active
    background: darken(#FF4242, 5)

.button__filled-clear
  background: transparent
  &:active
    opacity: 0.7
.button__outlined-green
  background: transparent
  border: 1px solid $green
  color: $green
  &:active
    opacity: 0.8
.button__outlined-white
  background: transparent
  border: 1px solid $white
  &:active
    opacity: 0.8








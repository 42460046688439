@import '../../../assets/styles/vars'

.home-screen
  flex: 1
  display: flex
  flex-direction: column
  background: none center
  background-size: cover
  height: 100%
  justify-content: space-between
  align-items: center
  overflow: hidden

  .content-area-wrapper
    display: flex
    width: 100%
    height: 100%
    flex-direction: column
    justify-content: center
    align-items: center

    .content-area
      display: flex
      width: 96%
      flex-direction: column
      justify-content: space-between
      align-items: center
      background: rgba(8, 55, 92, 0.85)
      border-radius: 5px
      padding: 60px 40px

      .welcome-to-label
        text-align: center
        text-transform: uppercase
        font-size: 42px
        letter-spacing: 10px
        margin-bottom: 20px

      .logo
        display: inline-block
        height: 120px
        & > img
          width: auto
          height: 100%
          object-fit: contain

      .logoTitle
          font-size: 50px
          font-family: $dearJoe4Font
          text-align: center
          width: 100%
          white-space: nowrap
          text-overflow: clip
          overflow: hidden

      .content-area__main-button
        width: 650px
        font-size: 44px
        font-weight: 600
        margin-top: 40px

      .content-area__buttons-wrapper
        display: flex
        width: 1000px
        flex-direction: row
        justify-content: center
        align-items: center
        flex-wrap: wrap
        margin: 40px
        font-size: 24px

        .content-area__button
          flex-basis: 380px
          flex-shrink: 0
          flex-grow: 0.23
          margin: 20px
          font-size: 30px

      .content-area__address
        display: flex
        text-align: center
        font-size: 32px

  .footer-area
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    background: rgba(8, 55, 92, 0.85)
    width: 100%
    height: 170px
    padding: 15px 30px 15px 30px

    .footer-area__info
      display: flex
      height: 100%
      flex-direction: column
      justify-content: space-between

      .footer-area__info-text
        font-size: 26px
        display: flex
        height: 66px
        flex-direction: column
        justify-content: space-between

      .footer-area__logo
        display: flex
        align-items: center

        .footer-area__logo-text
          font-size: 24px
          padding-left: 20px
          color: rgba($white, 0.8)

    .footer-area__invite
      display: flex
      flex-direction: column
      width: 450px
      height: 100%
      padding-top: 15px
      justify-content: space-between

      .footer-area__invite-text
        display: flex
        font-size: 26px
        align-self: center

      .footer-area__invite-buttons
        display: flex
        flex-direction: row
        justify-content: space-between

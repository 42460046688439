@import '../../../../assets/styles/vars'

.invite-success-view
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  width: 100%
  height: 100%
  background: linear-gradient(#06182E 0%, #063C68 60%, #063C68 70%, #06182E 100%)

  .title
    margin-top: 60px
    font-size: 64px
    font-weight: 600
    text-align: center

  .subtitle
    margin-top: 20px
    font-size: 38px
    font-weight: 400
    text-align: center

  .cancel-button
    align-self: center
    margin-top: 180px
    font-weight: 600
    width: 500px
    border-radius: 100px
    font-size: 34px
    box-shadow: 0 5px 10px 0 rgba(#000000, .2)

@import '../../../assets/styles/vars'

.emergency-screen
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%
  height: 100%
  padding-top: 200px
  box-sizing: border-box

.emergency-screen__back-button__wrapper
  position: fixed
  left: 0
  z-index: 2

  .back-button
    padding: 60px 60px 60px 40px

.emergency-screen__header
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  height: 200px
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 1
  background: #BD2531

.emergency-screen__header-title
  font-size: 48px
  font-weight: 900
  margin-right: 20px
  margin-left: 120px
  color: #F9C038

.emergency-screen__header-warning-icon
  width: auto
  height: 38px
  padding-top: 2px

.emergency-screen__content-wrapper
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  flex: 1
  overflow-y: auto
  background: #F2F2F2

.emergency-screen__content
  display: flex
  flex-direction: column
  justify-content: flex-start
  width: 86%
  height: 100%
  background: white

@import '../../../../../assets/styles/vars'

.denied-view
  display: flex
  align-items: center
  flex-direction: column
  justify-content: space-around

.denied-view__message
  margin-top: 80px
  font-size: 52px
  text-align: center
  line-height: 70px
  white-space: pre
  font-weight: 400

.denied-view__tenant-name
  font-weight: 700

.denied-view__cancel-button
  align-self: center
  margin-top: 180px
  font-weight: 600
  width: 520px
  border-radius: 100px
  font-size: 34px
  box-shadow: 0 5px 10px 0 rgba(#000000, .2)



@import '../../../assets/styles/vars'

.call-screen
  overflow: hidden
  position: absolute
  width: 100%
  height: 100%
  //background: linear-gradient(#06182E 0%, #063C68 60%, #063C68 70%, #06182E 100%)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  z-index: 1
  background: url(../../../assets/images/screen-background.png)
  background-size: cover

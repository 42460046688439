@import '../../../../../assets/styles/vars'

.vendor-code
  display: flex
  flex-direction: column
  justify-content: flex-start
  width: 100%
  height: 100%
  padding-top: 30px

  .date
    display: flex
    justify-content: flex-end
    align-items: center
    font-size: 24px
    height: 50px
    font-weight: 500
    color: black
    margin: 20px 40px 0 40px

  .content
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 100px

    .webcam-wrapper
      display: flex
      position: relative
      width: 350px
      height: 350px

      .vendor-content
        position: absolute
        bottom: 40px
        left: 50%
        transform: translate(-50%)

        .vendor-name
          text-align: center
          font-size: 24px
          font-weight: 700
          color: black

        .vendor-logo
          width: auto
          max-width: 90px
          height: auto
          max-height: 60px
          object-fit: contain

      .webcam
        width: 100%
        height: 100%
        object-fit: cover
        overflow: hidden
        border-radius: 50%
        background: #f2f2f2
        & > img
          width: 100%
          height: 100%
          object-fit: cover
        .webcam-video
          width: 100%
          height: 100%
          border-radius: 50%
          object-fit: cover
          overflow: hidden

      .capture-button
        align-items: center
        justify-content: center
        position: absolute
        border-radius: 50%
        right: -10px
        bottom: 0
        width: 96px
        height: 96px
        background: #08375C

    .input-wrapper
      display: flex
      flex-direction: column
      align-items: center
      margin-top: 50px

      .title
        display: flex
        text-align: center
        font-size: 30px
        font-weight: 500
        color: #676767

      .field-wrapper
        display: flex
        margin: 30px 0
        justify-content: center
        border-radius: 24px
        overflow: hidden
        background: #E6E6E6

        .field
          background: transparent
          width: 420px
          height: 90px
          border: none
          font-size: 42px
          font-weight: 700
          margin: 0 30px
          text-align: center
          line-height: 48px
          color: black
          @include placeholder()
            color: rgba(black, 0.3)
            text-align: center
            font-size: 30px
            font-weight: 500
            transform: translate(0, -3px)

      .send-button
        width: 420px
        font-size: 32px
        font-weight: 600
        margin-top: 60px

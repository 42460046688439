@import '../../../../../assets/styles/vars'

.code-type__back-wrapper
  position: absolute
  top: 0
  left: 0
  .back-button
    padding: 60px 60px 60px 40px

.code-type__content
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  width: 100%
  height: 100%
  .cloud
    width: 600px
    height: 400px
  .smile
    position: absolute
    top: 200px
    width: 140px
    height: auto

  .code-type__text
    margin-top: 80px
    margin-bottom: 80px
    font-size: 48px
    font-weight: 600
    text-align: center
  .code-type__buttons
    display: flex
    width: 760px
    height: 250px
    flex-direction: row
    justify-content: space-between
    align-items: center
    .code-type__resident
      width: 46%
      height: 100%
      border-radius: 10px
      background: #38AB4E
      color: white
      font-size: 38px
      font-weight: 600
      &:active
        background: darken(#38AB4E, 10)
    .code-type__guest
      width: 46%
      height: 100%
      border-radius: 10px
      background: #237FC8
      color: white
      font-size: 38px
      font-weight: 600
      &:active
        background: darken(#237FC8, 10)

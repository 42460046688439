.pending-screen
  display: flex
  flex: 1
  flex-direction: column
  justify-content: center
  align-items: center

  .pending-screen__info-holder
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    .pending-screen__text
      text-align: center
      margin-top: 50px
      font-size: 42px
      font-weight: 400

    .pending-screen__button
      margin-top: 120px
      width: 600px
      font-size: 40px
      font-weight: 500

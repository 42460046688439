@import '../../../../../../assets/styles/vars'

.content-name
  display: flex
  width: 100%
  height: 100%
  flex-direction: column
  align-items: center

  .content-name-header
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    margin-top: 180px

    .content-name-title
      text-align: center
      font-size: 48px
      font-weight: 600
      margin-top: 60px
      margin-bottom: 12px

    .content-name-subtitle
      text-align: center
      font-size: 38px

.content-name-input
  display: flex
  flex-direction: column
  align-items: center
  width: 540px
  padding: 60px 0

  .text-input
    width: 100%
    font-size: 52px
    font-weight: 600
    @include placeholder()
      color: rgba(white, 0.3)
      text-align: center
      font-size: 40px
      font-weight: 500
      transform: translate(0, -3px)

.content-name__connect-button
  box-shadow: 0 5px 10px 0 rgba(#000000, .2)
  width: 500px
  font-size: 36px
  font-weight: 600
  margin-top: 120px

.back-button-wrapper
  position: absolute
  top: 0
  left: 0

  .back-button
    padding: 60px 60px 60px 40px


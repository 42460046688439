@import '../../../../assets/styles/vars'

.invite-view
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  background: rgba(8, 55, 92, 0.85)

  .hand
    position: absolute
    right: 0
    bottom: 0
    width: 22%
    height: auto
    z-index: 1
    content: url(../../../../assets/images/mobile-hand.png)

  .content
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 56%
    height: 66%
    border-radius: 6px
    background: white
    padding-top: 40px

    .close-button
      position: absolute
      top: 0
      right: 0
      width: 86px
      height: 86px
      padding: 20px 20px 30px 30px
      justify-content: center
      align-items: center

    .title
      text-align: center
      font-size: 36px
      font-weight: 600
      color: black

    .subtitle
      text-align: center
      font-size: 28px
      font-weight: 500
      line-height: 36px
      color: black
      margin: 32px 0

    .dial
      position: relative
      display: flex
      justify-content: center
      align-items: center
      width: 90%

      .phone
        text-align: center
        font-size: 46px
        font-weight: 400
        color: black

      .remove-button
        position: absolute
        width: 80px
        height: 80px
        right: 0
        padding: 10px

    .list
      overflow: auto
      display: flex
      align-items: flex-start
      flex-wrap: wrap
      width: 74%
      margin-top: 14px
      margin-bottom: 30px

      .list-item
        flex: 1 1 33%
        padding: 0 0 33% 0
        max-width: 33%
        position: relative

        .list-item__content
          display: flex
          justify-content: center
          align-items: center
          position: absolute
          top: 0
          right: 0
          left: 0
          bottom: 0

          .list-item__button
            display: flex
            justify-content: center
            align-items: center
            width: 86%
            height: 86%
            background: white
            border-style: solid
            border-radius: 50%
            border-color: #08375C
            border-width: 2px

            .list-item__button-label
              width: 100%
              text-align: center
              font-size: 46px
              font-weight: 500
              color: black

            &:active
              border-width: 0
              background: #08375C

              .list-item__button-label
                color: white

    .send-button
      width: 65%
      font-size: 30px
      font-weight: 600

    .hint
      text-align: center
      font-size: 24px
      color: black
      opacity: 0.6
      margin-top: 20px
      font-weight: 400

@import '../../../../../assets/styles/vars'

.code-input__back-wrapper
  position: absolute
  top: 0
  left: 0

  .back-button
    padding: 60px 60px 60px 40px

.code-input
  display: flex
  flex-direction: column
  align-items: center
  width: 100%

  .cloud-wrapper
    display: flex
    align-items: flex-start
    margin-top: 80px
    position: relative

    .cloud
      width: 600px
      height: 400px

    .webcam
      position: absolute
      width: 220px
      height: 220px
      left: 50%
      top: 70px
      border-radius: 50%
      object-fit: cover

      .webcam-video
        width: 220px
        height: 220px
        border-radius: 50%
        object-fit: cover
        overflow: hidden
        background-color: black

  .content
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    margin-top: 70px
    width: 700px

    .inputs
      display: flex
      overflow: hidden
      flex-direction: column
      align-items: center
      justify-content: space-between
      margin: 50px 0 120px 0

      .input-wrapper
        display: flex
        align-items: center

        .label
          width: 260px
          font-size: 38px

        .field
          width: 340px
          font-size: 38px
          font-weight: 600
          margin-left: 20px
          background: transparent
          border: none
          border-bottom: 1px solid rgba(white, 1.0)
          text-align: center
          color: white
          padding: 15px 0 10px

        .field-with-error
          width: 180px
          font-size: 32px
          font-weight: 600
          margin-left: 20px
          background: transparent
          border: none
          border-bottom: 1px solid rgba(red, 1.0)
          text-align: center
          color: white
          padding: 15px 0 10px

    .send-button
      width: 540px
      font-size: 36px
      font-weight: 600

@import '../../../../../assets/styles/vars'

.emergency-list-item
  flex: 1 1 calc(33% - 20px)
  padding: 0 0 calc(33% - 20px) 0
  max-width: calc(33% - 20px)
  height: 320px
  margin: 10px
  position: relative

.emergency-list-item__content-wrapper
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  border-radius: 6px
  background: #f2f2f2
  top: 0
  right: 0
  left: 0
  bottom: 0

.emergency-list-item__content
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  width: 80%
  height: 80%

.emergency-list-item__content-icon
  width: 80%
  height: 80%
  object-fit: contain

.emergency-list-item__content-label
  width: 80%
  text-align: center
  font-size: 36px
  font-weight: 600
  color: black
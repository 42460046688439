.notification
  z-index: 100
  position: fixed
  display: inline-flex
  justify-content: center
  align-items: center
  left: 50%
  top: 20px
  padding: 15px 20px
  border-radius: 10px
  min-width: 230px
  background: white
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 10px
  &.popup-mode
    margin-left: -120px
@import '../../../../../assets/styles/vars'

.item
  flex-basis: calc(33% - 10px)
  padding: 0 0 calc(33% - 10px) 0
  max-width: calc(33% - 10px)
  height: 0
  margin: 5px
  position: relative

  .item__content-wrapper
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    border-radius: 6px
    background: #f2f2f2
    top: 0
    right: 0
    left: 0
    bottom: 0

    .item__content
      display: flex
      justify-content: center
      align-items: center
      width: 90%
      height: 90%
      background: white
      border-radius: 6px

      .item__label
        width: 100%
        text-align: center
        font-size: 24px
        font-weight: 500
        color: black

      .item__logo
        width: 80%
        height: 80%
        object-fit: contain

@import '../../../../assets/styles/vars'

.granted-view__wrapper
  display: flex
  flex-direction: column
  justify-content: center
  background: url(../../../../assets/images/screen-background.png)
  background-size: cover
  width: 100%
  height: 100%

.granted-view
  display: flex
  align-items: center
  flex-direction: column
  justify-content: space-around

.granted-view__text-title
  margin-top: 20px
  font-size: 100px
  text-align: center
  font-family: $dearJoe4Font

.granted-view__text-subtitle
  margin-top: -50px
  font-size: 28px
  text-align: center

.granted-view__text-door
  font-weight: 600

.granted-view__cancel-button
  position: absolute
  width: 100%
  height: 100%
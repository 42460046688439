@import '../../../../../assets/styles/vars'

.emergency-denied
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  width: 100%
  height: 100%
  background: linear-gradient(#06182E 0%, #063C68 60%, #063C68 70%, #06182E 100%)

.emergency-denied__message
  margin-top: 60px
  font-size: 46px
  text-align: center
  line-height: 50px
  white-space: pre

.emergency-denied__message-bold
  font-weight: 700

.emergency-denied__cancel-button
  align-self: center
  margin-top: 180px
  font-weight: 600
  width: 400px
  border-radius: 100px
  font-size: 34px
  box-shadow: 0 5px 10px 0 rgba(#000000, .2)

@import '../../../assets/styles/vars'

.back-button
  padding: 0 20px 0 10px
  align-items: center
  justify-content: center
  color: $white
  font-weight: 500
  transition: all 200ms
  &:active
    color: darken($white, 5)
    opacity: 0.8
  &:disabled
    pointer-events: none


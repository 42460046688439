@import '../../../../../assets/styles/vars'

.list__info-wrapper
  display: flex
  justify-content: space-between
  align-items: center
  height: 50px
  margin: 50px 40px 10px 40px

  .list__label
    font-size: 34px
    font-weight: 500
    color: black

  .list__date
    font-size: 24px
    font-weight: 500
    color: black

.list
  overflow: auto
  padding: 50px 30px 30px 30px
  display: flex
  align-items: flex-start
  flex-wrap: wrap

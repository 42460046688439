@import '../../../assets/styles/vars'

.user-pic
  border: none
  background-color: rgba(#ffffff, .5)

.user-pic_image
  background: none center
  background-size: cover

.user-pic__initials
  background: $gray2 center
  background-size: cover
  display: flex
  justify-content: center
  align-items: center
  color: #08375C
  font-weight: $medium


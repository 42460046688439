@import '../../../assets/styles/vars'

.registration-screen
  display: flex
  flex: 1
  flex-direction: column
  justify-content: center
  align-items: center

.registration-screen__holder
  display: flex
  flex-direction: column
  align-items: center

  .registration-screen__label
    font-weight: 400
    font-size: 44px

  .text-input
    width: 700px
    margin-top: 44px
    margin-bottom: 80px
    font-size: 54px
    font-weight: 600
    @include placeholder()
      color: rgba(white, 0.3)
      text-align: center
      font-size: 48px
      font-weight: 400

  .registration-screen__button
    width: 500px
    font-size: 40px
    font-weight: 500

$white: #ffffff
$black: #000000
$black1: 282829
$gray1: #3E3E3E
$gray2: #D2D2D2
$gray3: #F3F4F9
$gray4: rgba(3, 25, 41, 0.5)
$red1: #DC4333
$blue: #52A8DE
$blue1: #205BF6
$darkblue: #031e34
$darkblue2: #032640
$skyblue: #06335D
$lightblack: #333333
$text: #2e3540
$darkgray: #3E3E3E
$gray: #dfe2e6
$lightgray: #F8F8F8
$buttongray: #f2f2f2
$pink: #D8338D
$placeholder: #B1BFCD
$primary: #38AB4E
$green: #52de52
$secondary: #FFD500
$red: #f85959
$dimmed: #96A1B1


$sizeMedium: 1024px

$light: 300
$book: 400
$medium: 500
$demi: 600
$bold: 700
$extrabold: 800
$heavy: 900
$radius: 4px
$normal: unquote("normal")

$defaultFonts: Helvetica, Arial, sans-serif
$dearJoe4Font: 'dearJoe-4', $defaultFonts

$hoverTransition: all 0.15s ease-in-out
$hoverShadowTransition: box-shadow 0.2s ease-out
$shadowDefault: 0 2px 4px 0 rgba(66, 58, 83, 0.3)

@mixin max-visible-lines($fontSize, $lineHeight, $linesToShow)
  display: -webkit-box
  max-height: $lineHeight*$linesToShow
  font-size: $fontSize
  line-height: $lineHeight
  -webkit-line-clamp: $linesToShow
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis

@mixin center()
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

@mixin dashedBorder($top, $bottom)
  content: ''
  position: absolute
  top: $top
  bottom: $bottom
  left: -1000px
  right: -1000px
  height: 1px
  background-image: linear-gradient(to right, transparent 50%, #E1E1E1 40%)
  background-size: 16px 100%

/* Media queries mixins */
@mixin wide()
  @media (min-width: 1311px)
    @content

@mixin desktop()
  @media (max-width: 1310px)
    @content

@mixin laptop()
  @media (max-width: 1023px)
    @content

@mixin tablet()
  @media (max-width: 768px)
    @content

@mixin phone()
  @media (max-width: 480px)
    @content

@mixin media($width)
  @media only screen and (max-width: $width px)
    @content

@mixin placeholder()
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
  &:-moz-placeholder
    @content

@mixin autofill()
  &:-webkit-autofill,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover
    -webkit-animation-name: autofill
    -webkit-animation-fill-mode: both

@-webkit-keyframes autofill
  to
    background: transparent

@keyframes fadeInDown
  0%
    opacity: 0
    transform: translate3d(0, -5%, 0)
  100%
    opacity: 1
    transform: translate3d(0, 0, 0)

.fadeInDown
  animation: ease-out fadeInDown 800ms

@keyframes fadeInDownPage
  0%
    opacity: 0.3
    transform: translate3d(0, -2px, 0)
  100%
    opacity: 1
    transform: translate3d(0, 0, 0)

.fadeInDownPage
  animation: ease-out fadeInDownPage 400ms

@keyframes fadeInRightPage
  0%
    opacity: 0.3
    transform: scale(1.005)
  100%
    opacity: 1
    transform: scale(1)

.fadeInRightPage
  animation: ease-out fadeInRightPage 400ms

@keyframes blink
  0%
    opacity: .2
  20%
    opacity: 1
  100%
    opacity: .2

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

// --------- Opacity ---------

.opacity-enter
  opacity: 1

.opacity-enter-done,
.opacity-enter.opacity-enter-active
  opacity: 0.01
  transition: opacity 150ms ease-in

.opacity-exit
  opacity: 0

.opacity-exit-done

.opacity-exit.opacity-exit-active
  opacity: 1
  transition: opacity 150ms ease-in


// --------- Carousel ---------
.carousel-enter
  transform: translateX(50%)
  opacity: 0

.carousel-enter.carousel-enter-active
  transform: translateX(0)
  transition: all 0.3s ease-out
  opacity: 1

.carousel-leave
  transform: translateX(0)
  opacity: 1

.carousel-leave.carousel-leave-active
  transform: translateX(50%)
  transition: all 0.3s ease-out
  opacity: 0

// --------- slide-in ---------
.slide-down-enter
  transform: translateY(-50%)
  opacity: 0

.slide-down-enter.slide-down-enter-active
  transform: translateY(0)
  transition: all 0.3s ease-out
  opacity: 1

.slide-down-leave
  transform: translateY(0)
  opacity: 1

.slide-down-leave.slide-down-leave-active
  transform: translateY(-50%)
  transition: all 0.3s ease-out
  opacity: 0

@import '../../../assets/styles/vars'

.text-input
  background: transparent
  border: none
  border-bottom: 1px solid rgba(white, 1.0)
  font-size: 48px
  padding: 15px 0 10px
  text-align: center
  color: white
  @include placeholder()
    color: rgba(white, 0.3)
    text-align: center
    font-size: 48px

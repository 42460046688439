@import '../../../../../assets/styles/vars'

.code-granted__wrapper
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  width: 100%
  height: 100%

.code-granted
  display: flex
  align-items: center
  flex-direction: column
  .cloud
    width: 600px
    height: 400px
  .smile
    position: absolute
    top: 200px
    width: 140px
    height: auto

.code-granted__text-title
  margin-top: 80px
  font-size: 170px
  text-align: center
  font-family: $dearJoe4Font

.code-granted__text-subtitle
  margin-top: -70px
  font-size: 42px
  text-align: center

.code-granted__text-door
  font-weight: 600

.code-granted__cancel-button
  position: absolute
  width: 100%
  height: 100%

@import '../../../../../assets/styles/vars'

.call-view__content
  width: 100%
  height: 100%
  z-index: 2

  .call-view__local-view
    width: 280px
    height: 380px
    overflow: hidden
    border-radius: 5px
    background-color: black
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3)
    position: absolute
    bottom: 60px
    right: 30px
    video
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: cover
      transform: rotateY(180deg)

.call-view__remote-view
  width: 100%
  height: 100%
  position: absolute
  z-index: 1
  video
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover


.calling-view
  display: flex
  height: 100%
  flex-direction: column
  justify-content: space-between

.calling-view__tenant
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  margin-top: 200px

.calling-view__tenant-image
  margin: 0 0 30px 0
  box-shadow: 0 5px 10px 0 rgba(#000000, .2)

.calling-view__tenant-message
  font-size: 44px
  margin-top: 70px

.calling-view__tenant-name
  margin-top: 25px
  font-size: 58px
  font-weight: $bold

.calling-view__duration
  margin: 20px 0 0 0

.calling-view__decline-button
  align-self: center
  margin-bottom: 60px
  font-weight: 600
  width: 420px
  border-radius: 50%
  font-size: 36px
  box-shadow: 0 5px 10px 0 rgba(#000000, .2)

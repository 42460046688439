@import '../../../../../../assets/styles/vars'

#root
  display: block !important

.tabs
  display: flex
  width: 760px
  justify-content: space-between
  position: relative

.tabs__item
  display: flex
  align-items: center
  justify-content: center
  font-size: 46px
  font-weight: 600
  color: #868E9B
  .icon
    width: 38px
    height: auto
    margin-right: 18px
    path
      fill: #868E9B
  &.-active
    color: white
    path
      fill: white

.tabs__indicator
  height: 5px
  position: absolute
  top: 100%
  background: $primary
  transition: all 0.2s ease-in-out

@import '../../../../../assets/styles/vars'

.emergency-list
  flex: 1
  display: flex
  flex-direction: column

.emergency-list__date
  display: flex
  justify-content: flex-end
  font-size: 24px
  font-weight: 500
  color: black
  margin: 50px 40px 0 0

.emergency-list__content
  flex: 1 1 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  .list
    padding: 50px 10px 30px 10px
    width: 100%
    justify-content: center

.emergency-list__title
  width: 90%
  font-size: 38px
  font-weight: 500
  text-align: center
  color: black

.emergency-list-footer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 150px
  background-color: #BD2531

.emergency-list-footer__video-icon
  width: 40px
  height: auto

.emergency-list-footer__text
  width: 90%
  margin-top: 15px
  font-weight: 500
  font-size: 28px
  text-align: center
  color: white
